<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="增加菜单" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					<!-- 名称 -->
					<a-form-item label="金钱单位">
						<a-input  disabled v-decorator="[
							'price',
								{
								  initialValue: '元', 
								  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
								}, 
							]" />
					</a-form-item>

					<a-form-item label="数量单位">
						<a-input @change="getType" v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>

					<a-form-item label="混合单位">
						<a-input disabled v-decorator="[
							'mixins',
								{
								  initialValue: mixins+'/元', 
								  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
								}, 
							]" />
					</a-form-item>
				</a-form>
			</template>


		</a-modal>
	</div>
</template>

<script>
	export default {

		props: {
			record: Object
		},

		data() {
			return {
				show: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				mixins:""
			}
		},

		created() {

		},
		methods: {
			getType() {
			    this.$nextTick(() => {
			        console.log('form:{}', this.form.getFieldValue('name'));
			        this.mixins = this.form.getFieldValue('name')
			    });
			},
			// 获取表单验证值
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
