var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"okText":"确定","cancelText":"取消","title":"增加菜单"},on:{"ok":_vm.comfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('a-form',{ref:"collectionForm",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"金钱单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'price',
							{
							  initialValue: '元', 
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							}, 
						]),expression:"[\n\t\t\t\t\t\t'price',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t  initialValue: '元', \n\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写名称`, whitespace: true }],\n\t\t\t\t\t\t\t}, \n\t\t\t\t\t\t]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"数量单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{
						  initialValue: _vm.record.data.name,
						  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.name,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写名称`, whitespace: true }],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}],on:{"change":_vm.getType}})],1),_c('a-form-item',{attrs:{"label":"混合单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'mixins',
							{
							  initialValue: _vm.mixins+'/元', 
							  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
							}, 
						]),expression:"[\n\t\t\t\t\t\t'mixins',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t  initialValue: mixins+'/元', \n\t\t\t\t\t\t\t  rules: [{ required: true, message: `请填写名称`, whitespace: true }],\n\t\t\t\t\t\t\t}, \n\t\t\t\t\t\t]"}],attrs:{"disabled":""}})],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }