<template>
	<div class="content">
		<!-- 添加分类 -->
		<a-button class="mb-15" type="primary" @click="add">
		  添加单位
		</a-button>
		<!-- 表格 -->
		<Table
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"
		@gettabledata="gettabledata" 
		@modify = "modify"
		@del = "del"
		>
		</Table>
		
		
		<!-- 修改 -->
		<Modify ref="modify" :record="record"  @modifynav="modifynav"></Modify>
		
		<!-- Add -->
		<Add ref="add" :record="record"  @modifynav="addnav"></Add>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import Table from "../../components/unit/table.vue"
	import Modify from "../../components/unit/modify.vue"
	import Add from "../../components/unit/add.vue"
	export default {
		components:{
			Table,
			Modify,
			Add
		},
		data() {
			return {
				record:{
					data:"",
				},
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '金钱单位',
					key:"dollar",
				    dataIndex: 'dollar',
					align: 'center' 
				  },
				  {
				    title: '数量单位',
					key:"unit",
				    dataIndex: 'unit',
					align: 'center' 
				  },
				  {
				    title: '混合单位',
				  	key:"mixins",
				    dataIndex: 'mixins',
				  	align: 'center' ,
					slots: { title: 'mixins' }, //插槽列
					scopedSlots: { customRender: "mixins" },//插槽列数据
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: 'action' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:[
					{
						sort:1,
						id:1,
						name:"家电清洗",
						classify:"服务",
						icon:"icon",
						show:false,
						time:"2012-03-34",
						person:"马先生",
						control:"修改"
					},
					{
						sort:2,
						id:2,
						name:"家电产生的的清洗",
						classify:"服务",
						icon:"icon",
						show:true,
						time:"2012-03-34",
						person:"重先生",
						control:"修改"
					},
				]
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods:{
			...mapActions({
				getunit:"appletsetunit/getunit",
				getupdate:"appletsetunit/getupdate",
				getadd:"appletsetunit/getadd",
				getdel:"appletsetunit/getdel",
				commone:"index/commone",
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getunit(pagination)
				 this.pagination.current = pagination.current
				 this.pagination.total = data.total
				 this.pagination.totalPage = data.totalPage
				 this.datasource = data.data
			
			},
			// 打开添加弹窗
			add(){
				// 每一行数据record
				let record = {
					name:"",
				}
				this.record.data = record
				this.$refs.add.show = true
				
			},
			// addnav 添加提交
			async addnav(payload){
				console.log(payload,"提交参数");
				let payloads = {
					name:payload.name
				}
				let msg = await this.getadd(payloads)
				this.$message.success(msg)
				//刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.add.show = false
			},
			// 打开修改弹窗
			async modify(record){
				// 每一行数据record
				console.log(record);
				this.record.data = record
				this.$refs.modify.show = true
				
			},
			// 修改提交
			async modifynav(payload){
				let payloads = {
					id:payload.id,
					name:payload.name
				}
				console.log(payloads,"提交参数");
				let msg = await this.getupdate(payloads)
				this.$message.success(msg)
				//刷新数据
				await this.gettabledata(this.pagination)
				this.$refs.modify.show = false
			},
			// 删除
			async del(e){
				// 
				console.log(e);
				let msg = await this.getdel(e.id)
				this.$message.success(msg)
				// //刷新数据
				this.gettabledata(this.pagination)
			}
		}
	}
</script>

<style>
</style>
